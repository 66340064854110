import { createRouter } from "vue-router"
import { createWebHistory } from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'main_page',
            component: () => import('./views/Main'),
            meta: {
                title: 'Главная страница'
            }
        },
        {
            path: '/report/:id',
            name: 'report_view',
            component: () => import('./views/Report'),
            meta: {
                title: 'Отчёт №',
                param: 'id'
            }
        },
        {
            path: '/delete-report/:id',
            name: 'delete_report_view',
            component: () => import('./views/DeleteReport'),
            meta: {
                title: 'Отчёт на удаление №',
                param: 'id'
            }
        },
        {
            path: '/event',
            name: 'event_view',
            component: () => import('./views/Event'),
            meta: {
                title: 'Событие №',
                query: 'eventId'
            }
        },
        {
            path: '/settings/users',
            name: 'users',
            component: () => import('./views/settings/Users'),
            meta: {
                title: 'Пользователи'
            }
        },
        {
            path: '/settings/parameters',
            name: 'parameters',
            component: () => import('./views/settings/Parameters'),
            meta: {
                title: 'Параметры'
            }
        },
        {
            path: '/functions/borrowers',
            name: 'borrowers',
            component: () => import('./views/functions/Borrowers'),
            meta: {
                title: 'Заёмщики'
            }
        },
        {
            path: '/functions/borrower/:uuid',
            name: 'borrower_view',
            component: () => import('./views/functions/BorrowerView'),
            meta: {
                title: 'Заёмщик №',
                param: 'uuid'
            }
        },
        {
            path: '/functions/loans',
            name: 'loans',
            component: () => import('./views/functions/Loans'),
            meta: {
                title: 'Займы'
            }
        },
        {
            path: '/functions/loan/:id',
            name: 'loan_view',
            component: () => import('./views/functions/LoanView'),
            meta: {
                title: 'Займ №',
                param: 'id'
            }
        },
        {
            path: '/functions/close-loan',
            name: 'loan_close',
            component: () => import('./views/functions/CloseLoan'),
            meta: {
                title: 'Закрытие займа'
            }
        },
        {
            path: '/functions/crypt',
            name: 'crypt',
            component: () => import('./views/functions/Crypt'),
            meta: {
                title: 'Шифрование'
            }
        },
        {
            path: '/functions/report-responses',
            name: 'report-responses',
            component: () => import('./views/functions/ReportResponses'),
            meta: {
                title: 'Файлы ответа'
            }
        },
        {
            path: '/functions/delete-ch',
            name: 'delete-ch',
            component: () => import('./views/functions/DeleteCh.vue'),
            meta: {
                title: 'Удаление кредитной истории'
            }
        },
        {
            path: '/functions/delete-ip',
            name: 'delete-ip',
            component: () => import('./views/InformationPart/DeleteInformationPart.vue'),
            meta: {
                title: 'Удаление информационной части'
            }
        },
        {
            path: '/functions/delete-ip/:id',
            name: 'delete_information_part_view',
            component: () => import('./views/InformationPart/DeleteInformationPartView.vue'),
            meta: {
                title: 'Отчёт на удаление ИЧ №',
                param: 'id'
            }
        },
        {
            path: '/functions/deleted-events',
            name: 'delete_events_view',
            component: () => import('./views/DeleteEvents/DeleteEventsView.vue'),
        },
        {
            path: '/functions/stopped-loads-list',
            name: 'stopped-loads-list',
            component: () => import('./views/functions/StoppedLoadsList'),
            meta: {
                title: 'Список остановленных передач'
            }
        },
        {
            path: '/functions/stop-load',
            name: 'stop-load',
            component: () => import('./views/functions/StopLoad'),
            meta: {
                title: 'Остановка передачи в БКИ'
            },
        },
        {
            path: '/functions/okb-report',
            name: 'okb-report',
            component: () => import('./views/functions/OkbReport'),
            meta: {
                title: 'Создание отчёта в ОКБ'
            }
        },
        {
            path: '/bureau/blocks-info',
            name: 'blocks-info',
            component: () => import('./views/Bureau/BlocksInfoView'),
            meta: {
                title: 'Просмотр данных по займу'
            }
        },
        {
            path: '/admin',
            name: 'admin',
            component: () => import('./views/admin/Admin'),
            meta: {
                title: 'Администрирование'
            }
        }
    ]
})

router.beforeEach((toRoute, fromRoute, next) => {
    let title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title + ' | БКИ Сервис' : 'БКИ Сервис'
    if (toRoute.meta && toRoute.meta.param && toRoute.meta.title) {
        title = toRoute.meta.title + toRoute.params[toRoute.meta.param] + ' | БКИ Сервис'
    }
    if (toRoute.meta && toRoute.meta.query && toRoute.meta.title) {
        title = toRoute.meta.title + toRoute.query[toRoute.meta.query] + ' | БКИ Сервис'
    }
    window.document.title = title

    next()
})

export default router