<template>
  <div>
    <div v-if="loading">
      <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <ProgressSpinner></ProgressSpinner>
      </div>
    </div>
    <Toast/>
    <ConfirmDialog/>
    <DynamicDialog/>
    <div v-if="!loading && $store.state.user !== null && $store.state.user.isPasswordChanged" class="grid">
      <div class="col-12">
        <Menubar :model="menu">
          <template #start><h3>БКИ Сервис | {{ compendiumService.getValueByCode($store.state.compendiums.brand, 'name') }}</h3></template>
          <template #end>
            <div class="grid">
              <div class="col-12">
                <div class="p-buttonset">
                  <Button @click="toggle" icon="pi pi-user"></Button>
                </div>
              </div>
            </div>
          </template>
        </Menubar>
        <div class="grid">
          <div class="col-12">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <OverlayPanel ref="userPanel" append-to="body">
        <p><strong>{{ $store.state.user.surname }} {{ $store.state.user.name }} {{ $store.state.user.patronymic }}</strong></p>
        <p>{{ compendiumService.getValueByCode($store.state.compendiums.userRole, $store.state.user.role) }}</p>
        <p v-if="$store.state.user.role === 'admin'"><Button @click="goToAdmin" label="Функицонал администратора"></Button></p>
        <p><Button icon="pi pi-sign-out" label="Выйти" @click="logout"></Button></p>
      </OverlayPanel>
    </div>
    <div v-if="!loading && $store.state.user === null" class="grid">
      <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <Auth></Auth>
      </div>
    </div>
    <div v-if="!loading && $store.state.user !== null && !$store.state.user.isPasswordChanged" class="grid">
      <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <ChangePassword></ChangePassword>
      </div>
    </div>
  </div>
</template>

<script>

import AuthService from "@/service/AuthService";
import Auth from "@/components/Auth";
import ChangePassword from "@/components/ChangePassword";
import CompendiumService from "@/service/CompendiumService";

export default {
  name: 'App',
  components: {ChangePassword, Auth},
  data() {
    return {
      compendiumService: CompendiumService,
      loading: true,
      menu: [
        {
          label: 'Главная',
          icon: 'pi pi-fw pi-table',
          to: '/'
        },
        {
          label: 'Корректировка',
          icon: 'pi pi-fw pi-pencil',
          items: [
            {
              label: 'Удаление данных',
              icon: 'pi pi-fw pi-trash',
              items: [
                {
                  label: 'Удаление КИ',
                  icon: 'pi pi-fw pi-credit-card',
                  to: '/functions/delete-ch'
                },
                {
                  label: 'Удаление ИЧ',
                  icon: 'pi pi-fw pi-info-circle',
                  to: '/functions/delete-ip'
                },
                {
                  label: 'Удаленные события',
                  icon: 'pi pi-fw pi-ticket',
                  to: '/functions/deleted-events'
                },
                {
                  label: 'Остановка передачи в БКИ',
                  icon: 'pi pi-fw pi-stop-circle',
                  to: '/functions/stop-load'
                },
                {
                  label: 'Список остановленных передач',
                  icon: 'pi pi-fw pi-list',
                  to: '/functions/stopped-loads-list'
                }
              ]
            },
            {
              label: 'Закрытие займа',
              icon: 'pi pi-fw pi-times-circle',
              to: '/functions/close-loan'
            }
          ]
        },
        {
          label: 'Функционал',
          icon: 'pi pi-fw pi-sliders-h',
          items: [
            {
              label: 'Заёмщики',
              icon: 'pi pi-fw pi-users',
              to: '/functions/borrowers'
            },
            {
              label: 'Займы',
              icon: 'pi pi-fw pi-money-bill',
              to: '/functions/loans'
            },
              {
                label: 'Внешние бюро',
                icon: 'pi pi-fw pi-database',
                items: [
                  {
                    label: 'Просмотр данных по займу',
                    icon: 'pi pi-fw pi-info-circle',
                    to: '/bureau/blocks-info'
                  },
                  {
                    label: 'Файлы ответа',
                    icon: 'pi pi-fw pi-reply',
                    to: '/functions/report-responses'
                  },
                ]
              },
            {
              label: 'Шифрование',
              icon: 'pi pi-fw pi-key',
              to: '/functions/crypt'
            },
            {
              label: 'Отчёт в ОКБ',
              icon: 'pi pi-fw pi-report',
              to: '/functions/okb-report'
            },
            {
              label: 'FTP Эквифакс',
              icon: 'pi pi-fw pi-file',
              url: 'https://bki-file.beriberu.ru'
            }
          ]
        },
        {
          label: 'Настройки',
          icon: 'pi pi-fw pi-cog',
          items: [
            {
              label: 'Пользователи',
              icon: 'pi pi-fw pi-users',
              to: '/settings/users'
            },
            {
              label: 'Параметры',
              icon: 'pi pi-fw pi-cog',
              to: '/settings/parameters'
            }
          ]
        }
      ],
    }
  },
  methods: {
    goToAdmin() {
      this.$router.push('/admin')
      this.$refs.userPanel.hide()
    },
    toggle(event) {
      this.$refs.userPanel.toggle(event)
    },
    logout() {
      AuthService.logout()
    }
  },
  mounted() {
    CompendiumService.init(this.$toast, (compendiums, tables) => {
      AuthService.validate().then((r) => {
        this.$store.state.compendiums = compendiums
        this.$store.state.tables = tables
        this.$store.state.user = r.user
        this.loading = false
      }).catch((err) => {
        this.$store.state.compendiums = compendiums
        this.$store.state.tables = tables
        if (err.code === -32000) {
          this.loading = false
        }
      })
    })
  }
}
</script>

<style>
</style>
